import React, { useState, useRef, useEffect  }  from 'react';
import { List, Datagrid, TextField, TextInput, useRecordContext, useNotify, fetchUtils, useRefresh, Pagination, FilterForm, FilterButton } from 'react-admin';
import "./style.css";
import { Stack } from '@mui/material';

const historyFilters = [
    <TextInput label="Bot Type" source="bot_type" />,
    <TextInput label="Name" source="name"/>,
    <TextInput label="Email" source="email" />,
    <TextInput label="Chat Start Time" source="chat_start_time" />
];

const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between" style={{ paddingLeft: '20px' }}>
        <FilterForm filters={historyFilters} />
        <div>
            <FilterButton filters={historyFilters} />
        </div>
    </Stack>
)

const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  

const PostPanel = () => {
    const notify = useNotify();
    const refresh = useRefresh();

    const record = useRecordContext();
    const [editingIndex, setEditingIndex] = useState(-1); 
    const chatLineInputRefs = useRef([]); 

    useEffect(() => {  
        // Initialize the chatLineInputRefs object with the correct length  
        const lines = record.chat_history.trim().split('\n');  
        for (let i = 0; i < lines.length; i++) {  
          chatLineInputRefs.current[i] = null;  
        }  
      }, [record.chat_history]);  
    
    const handleEdit = (index) => {  
        setEditingIndex(index);  
    };  

    const handleSave = async (index, original_question, original_answer) => {
        const newQuestion = chatLineInputRefs.current[index].value;  
        const newAnswer = chatLineInputRefs.current[index + 1].value;  

        record.chat_history = record.chat_history.replace(original_question, newQuestion)
        record.chat_history = record.chat_history.replace(original_answer, newAnswer)

        if (newQuestion && newAnswer) {  
            const add_question_answer_url = `${apiUrl}/add_question_answer`;  
            const update_history_url = `${apiUrl}/update_history`;
            // Prepare the data you want to send  
            const data = {  
                question: newQuestion,
                answer: newAnswer,
                original_question: original_question,
                original_answer: original_answer
            };  
    
            try {  
                // Send POST request  
                const { status } = await httpClient(add_question_answer_url, {  
                    method: 'POST',
                    body: JSON.stringify(data),
                });  
    
                if (status >= 200 && status < 300) {  
                    notify(`Added New Question and Answer`, { type: 'info' });  
                } else {  
                    notify(`Failed to add question and answer.`, 'error');  
                } 
               
            } catch (error) {  
                console.error('Error adding question and answer:', error);  
                notify(`An error occurred: ${error.message}`, 'error');  
            }  

            // Prepare the data you want to send  
            const history = {  
                history_id: record._id,
                history: record.chat_history
            };  

            try {  
                // Send POST request  
                const { status } = await httpClient(update_history_url, {  
                    method: 'POST',
                    body: JSON.stringify(history),
                });  
             
                if (status >= 200 && status < 300) {  
                    notify(`Update ChatHistory`, { type: 'info' });  
                } else {  
                    notify(`Failed to update chat history.`, 'error');  
                } 
                refresh(); 
            } catch (error) {  
                console.error('Error Update chat history:', error);  
                notify(`An error occurred: ${error.message}`, 'error');  
            }  

        } else {  
            notify(`Please input question and answer.`, {type: 'warning'});  
        }  

        
        // Update the record.chat_history with the new pair  
        // const updatedChatHistory = record.chat_history.split('\n');  
        // updatedChatHistory.splice(index, 2, updatedLine1, updatedLine2);  
        // record.chat_history = updatedChatHistory.join('\n'); 

        // Save the updated chat lines for the pair at the given index  
        // You'll need to implement the logic to update the record.chat_history  
        setEditingIndex(-1);  
    };  

    const chatLines = record.chat_history.trim().split(/Customer|Ai bot/).reduce((acc, line, index) => {  
        if (index % 2 === 1 && index < record.chat_history.trim().split(/Customer|Ai bot/).length - 1) {  
        acc.push(  
            <div key={index} className="chat-pair">  
            {editingIndex === index ? (  
                <>  
                <input  
                    type="text"  
                    className="chat-line-input"  
                    defaultValue={line.replace(/^:\s*/, '')} 
                    style={{width: '100%', marginBottom: '2px', marginTop: '2px'}}
                    ref={(ref) => (chatLineInputRefs.current[index] = ref)} 
                /> <br /> 
                <input  
                    type="text"  
                    className="chat-line-input"  
                    defaultValue={record.chat_history.trim().split(/Customer|Ai bot/)[index + 1].replace(/^:\s*/, '')} 
                    style={{width: '100%', marginBottom: '2px', marginTop: '2px'}} 
                    ref={(ref) => (chatLineInputRefs.current[index + 1] = ref)} 
                />  
                <div className="chat-buttons">  
                    <button className="save-btn" style={{marginRight: '5px'}} onClick={() => handleSave(index, line.replace(/^:\s*/, ''), record.chat_history.trim().split(/Customer|Ai bot/)[index + 1].replace(/^:\s*/, ''))}>  
                    Save  
                    </button>  
                    <button className="cancel-btn" onClick={() => setEditingIndex(-1)}>  
                    Cancel  
                    </button>  
                </div>  
                </>  
            ) : (  
                <>  
                <div className="chat-line">Customer {line}</div>  
                <div className="chat-line">  
                    Ai Bot {record.chat_history.trim().split(/Customer|Ai bot/)[index + 1]}  
                </div>  
                <div className="chat-buttons">  
                    <button className="edit-btn" onClick={() => handleEdit(index)}>  
                    Edit  
                    </button>  
                </div>  
                </>  
            )}  
            </div>  
        );  
        }  
        return acc;  
    }, []);  

    return <div className="chat-history">{chatLines}</div>;  
};

const FullAnswerField = () => {
    const record = useRecordContext();
    const [chatHistory, setChatHistory] = useState(record.chat_history || ''); 
    if (!record || !record.chat_history) return null;

    if (record.chat_history.length > 150) {
        return <span>{record.chat_history.substring(0, 150) + '...'}</span>;
    }

    return <span>{record.chat_history}</span>
};

const ChatHistoryList = (props) => {
    return (
        <List title='Chat Histories'  {...props}  pagination={<Pagination />} >
            <ListToolbar />
            <Datagrid rowClick="edit" expand={<PostPanel />}>
                {/* <TextField source='_id' /> */}
                <TextField source='bot_type' />
                <TextField source='name' />
                <TextField source='email' />
                <FullAnswerField source='chat_history'/>
                <TextField source='chat_start_time' />
            </Datagrid>
        </List>
    );
};

// Optional: Add PropTypes for better type checking
ChatHistoryList.propTypes = {
    // Define any props here if needed
};

export default ChatHistoryList;
