import { fetchUtils } from 'react-admin';

const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  

const customDataProvider = {
    getList: async (resource, params) => {
        let url;

        if (resource === 'chat_history') {
            // Ensure params.filter.customer_number is defined
            url = `${apiUrl}/history`;
        } else if (resource === 'chat_summary') {
            url = `${apiUrl}/summary`;
        } else if (resource === 'customers') {
            url = `${apiUrl}/customer`;
        } else if (resource === 'users') {
            url = `${apiUrl}/get_user`;
        } else if (resource === 'question_answer') {
            url = `${apiUrl}/get_question_answer`;
        } else if (resource === 'prompt') {
            url = `${apiUrl}/get_prompt`;
        } else if (resource === 'bot') {
            url = `${apiUrl}/get_bot`;
        }else {
            throw new Error('Unknown resource');
        }

        // Construct query parameters based on filters
        const { filter, pagination, sort } = params;
        const query = {
            ...filter,
            // Add pagination and sorting if needed
            _page: pagination.page,
            _limit: pagination.perPage,
            _sort: sort.field,
            _order: sort.order,
        };

        // Append query parameters to the URL
        const queryString = new URLSearchParams(query).toString();
        const finalUrl = `${url}?${queryString}`;

        const { json } = await httpClient(finalUrl);
        
        // Ensure the response data is in the correct format
        return {
            data: json.data.map(item => ({
                ...item,
                id: item._id || item.customer_number // Ensure each object has an 'id'
            })),
            total: json.total,
        };
    },
    // Other methods...
};

export default customDataProvider;
