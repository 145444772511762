import React from 'react';
import he from 'he';  
import { List, Datagrid, TextInput, TextField, useRecordContext, Pagination, FilterForm, FilterButton } from 'react-admin';
// import "./style.css";
import { Stack } from '@mui/material';

const historyFilters = [
    <TextInput label="Bot Type" source="bot_type" />,
    <TextInput label="Name" source="name"/>,
    <TextInput label="Email" source="email" />
];

const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between" style={{ paddingLeft: '20px' }} >
        <FilterForm filters={historyFilters} />
        <div>
            <FilterButton filters={historyFilters} />
        </div>
    </Stack>
)

const PostPanel = () => {
    const record = useRecordContext();
    return (
        <div dangerouslySetInnerHTML={{ __html: he.decode(record.chat_summary) }} />
    );
};

const FullHistoryField = () => {
    const record = useRecordContext();
    // Check if the chat_summary exists before accessing its length  
    if (record.chat_summary && record.chat_summary.length > 150) {  
        return <span>{he.decode(record.chat_summary.substring(0, 150)) + '...'}</span>;  
    } else if (record.chat_summary) {  
        // If it is defined but less than or equal to 150 characters, show it fully  
        return <span>{he.decode(record.chat_summary)}</span>;  
    }  

    // If chat_summary is null or undefined, return an alternative JSX (e.g., empty or a placeholder)  
    return <span>No summary available</span>;  
}

const ChatSummaryList = (props) => {

    return (
        <List title='Chat Summaries' {...props} pagination={<Pagination />}>
            <ListToolbar />
            <Datagrid rowClick="edit" expand={<PostPanel />}>
                {/* <TextField source='_id' /> */}
                <TextField source='bot_type' />
                <TextField source='name' />
                <TextField source='email' />
                <FullHistoryField source='chat_summary' />
            </Datagrid>
        </List>
    );
};

export default ChatSummaryList;
