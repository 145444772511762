import React, { useState } from 'react';
import { List, Datagrid, TextField, useRecordContext, useNotify, fetchUtils, TopToolbar, TextInput, Form, useRefresh } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./style.css";


const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  

const PromptList = (props) => {

    const notify = useNotify();
    const refresh = useRefresh();
    const [openAddPrompt, setOpenAddPrompt] = useState(false);
    const [newPrompt, setNewPrompt] = useState('');

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deletePrompt, setDeletePrompt] = useState(null);

    const [selectOpen, setSelectOpen] = useState(false);
    const [selectPrompt, setSelectPrompt] = useState(null);

    const [updatePrompt, setUpdatePrompt] = useState(null);
    const [openUpdatePrompt, setOpenUpdatePrompt] = useState(false);

    const handleAddOpen = (e) => {
        setOpenAddPrompt(true);
    };

    const handleAddClose = () => {
        setOpenAddPrompt(false);
    };

    const handleAddConfirm = async () => {  
        if (newPrompt) {  
            const add_prompt_url = `${apiUrl}/add_prompt`;  
    
            // Prepare the data you want to send  
            const data = {  
                prompt: newPrompt
            };  
    
            try {  
                // Send POST request  
                const { status } = await httpClient(add_prompt_url, {  
                    method: 'POST',
                    body: JSON.stringify(data),
                });  
    
                if (status >= 200 && status < 300) {  
                    notify(`Added New Question and Answer`, { type: 'info' });  
                    setNewPrompt('');  
                    handleAddClose();  
                } else {  
                    notify(`Failed to add question and answer.`, 'error');  
                } 
                refresh(); 
            } catch (error) {  
                console.error('Error adding question and answer:', error);  
                notify(`Error adding prompt.`, {type: 'warning'});  
            }  
        } else {  
            notify(`Please input question and answer.`, {type: 'warning'});  
        }  
    };

    const UpdateButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setUpdatePrompt(record);
            setNewPrompt('');
            handleUpdateOpen(true);
        }
        return <button onClick={handleClick}>Update</button>;
    };

    const handleUpdateOpen = (e) => {
        setOpenUpdatePrompt(true);
    };

    const handleUpdateClose = () => {
        setOpenUpdatePrompt(false);
        setNewPrompt('');
    };

    const handleUpdateConfirm = async () => {  
        if (newPrompt) {  
            const Update_Prompt_url = `${apiUrl}/update_prompt`;  

            // Prepare the data you want to send  
            const data = {  
                prompt: newPrompt,
                prompt_id: updatePrompt._id  
            };  
    
            try {  
                // Send POST request  
                const { status } = await httpClient(Update_Prompt_url, {  
                    method: 'POST',
                    body: JSON.stringify(data),
                });  
    
                if (status >= 200 && status < 300) {  
                    notify(`Updated Prompt`, { type: 'info' });  
                    setNewPrompt('');
                    handleUpdateClose();  
                } else {  
                    notify(`Failed to Update Prompt.`, 'error');  
                } 
                refresh(); 
            } catch (error) {  
                console.error('Error updateing Prompt:', error);  
                notify(`Error updating prompt.`, {type: 'warning'});   
            }  
        } else {  
            notify(`Please input prompt.`, {type: 'warning'});  
        }  
    };

    const DeleteButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setDeletePrompt(record);
            setDeleteOpen(true);
        }
        return <button onClick={handleClick}>Delete</button>;
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeletePrompt(null);
    };

    const handleDeleteConfirm = async () => {
        if (deletePrompt) {
            await prompt_delete(deletePrompt);
            handleDeleteClose();
        }
    };

    const prompt_delete = async (deletePrompt) => {
        try {
            const delete_url = `${apiUrl}/delete_prompt/${deletePrompt._id}`;
            await httpClient(delete_url);
            // notify(`Calling ${phone_number}`, 'info');
            notify(`Delete Question and Answer`, {type: 'info'});
            refresh();
        } catch (e) {
            console.error(e);
            notify(`Error delete Question and Answer`, {type: 'warning'});
        }
    };

    const SelectButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setSelectPrompt(record);
            setSelectOpen(true);
        }
        if (record.is_selected === false)
            return <button onClick={handleClick}>Select</button>;
        else 
            return  <button disabled style={{ cursor: 'not-allowed', opacity: 0.5 }}>Select</button>; 
            

    };

    const handleSelectClose = () => {
        setSelectOpen(false);
        setSelectPrompt(null);
    };

    const handleSelectConfirm = async () => {
        if (selectPrompt) {
            await prompt_select(selectPrompt);
            handleSelectClose();
        }
    };

    const prompt_select = async (selectPrompt) => {
        try {
            const delete_url = `${apiUrl}/prompt/${selectPrompt._id}`;
            await httpClient(delete_url);
            // notify(`Calling ${phone_number}`, 'info');
            notify(`Select new prompt`, {type: 'info'});
            refresh();
        } catch (e) {
            console.error(e);
            notify(`Error seleting Question and Answer`, {type: 'warning'});
        }
    };

    return (
        <>
            <TopToolbar>
                <Button onClick={(e) => handleAddOpen(e)}>Add New</Button>
            </TopToolbar>
            <List title='Prompts' {...props}>
                <Datagrid rowClick="edit">
                    {/* <TextField source='_id' /> */}
                    <TextField source='prompt' />
                    <SelectButton />
                    <UpdateButton />
                    <DeleteButton />
                </Datagrid>
            </List>
        
              {/* Add new question and answer */}
              <Dialog open={openAddPrompt} onClose={handleAddClose}>
                <DialogTitle>Add New Prompt</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input Prompt
                    </DialogContentText>
                    <Form record={{ id: 123, title: 'Lorem ipsum' }}>
                        <TextInput   
                            source='prompt'   
                            value={newPrompt}   
                            onChange={(e) => setNewPrompt(e.target?.value)}   
                            resettable 
                            multiline   
                        />   
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddConfirm} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm delete prompt */}
            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this prompt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

             {/* Confirm select prompt */}
             <Dialog open={selectOpen} onClose={handleSelectClose}>
                <DialogTitle>Confirm Select</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to select this prompt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSelectClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSelectConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Update prompt */}
            <Dialog open={openUpdatePrompt} onClose={handleUpdateClose}>
                <DialogTitle>Update Prompt</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input Prompt
                    </DialogContentText>
                    <Form record={{ id: 123, title: 'Lorem ipsum' }}>
                        <TextInput   
                            source="prompt"   
                            value={newPrompt}   
                            onChange={(e) => setNewPrompt(e.target?.value)}   
                            resettable 
                            multiline  
                        />  
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateConfirm} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default PromptList;
