import React, { useState } from 'react';
import { List, Datagrid, useRecordContext, TextField, TextInput, useNotify, fetchUtils, useRefresh, Pagination, FilterForm, FilterButton  } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./style.css";
import { Stack } from '@mui/material';

// import FileEditor from './FileEditor';

const customerFilters = [
    <TextInput label="Bot Type" source="bot_type" />,
    <TextInput label="Name" source="name"/>,
    <TextInput label="Email" source="email" />,
    <TextInput label="Phone" source="phone"/>
];

const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between" style={{ paddingLeft: '20px' }}>
        <FilterForm filters={customerFilters} />
        <div>
            <FilterButton filters={customerFilters} />
        </div>
    </Stack>
)

const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  


const CustomerList = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteCustomer, setDeleteCustomer] = useState(null);
    const [stopOpen, setStopOpen] = useState(false);
    const [stopCustomer, setStopCustomer] = useState(null);

 
    const DeleteButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setDeleteCustomer(record);
            setDeleteOpen(true);
        }
        return <button onClick={handleClick}>Delete</button>;
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteCustomer(null);
    };

    const handleDeleteConfirm = async () => {
        if (deleteCustomer) {
            await customer_delete(deleteCustomer);
            handleDeleteClose();
        }
    };

    const customer_delete = async (deleteCustomer) => {
        try {
            const delete_url = `${apiUrl}/delete/${deleteCustomer._id}`;
            await httpClient(delete_url);
            // notify(`Calling ${phone_number}`, 'info');
            notify(`Delete Customer ${deleteCustomer.name}`, {type: 'info'});
            refresh();
        } catch (e) {
            console.error(e);
            notify(`Error delete ${deleteCustomer.name}`, {type: 'warning'});
        }
    };

    const StopButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setStopCustomer(record);
            setStopOpen(true);
        }
        if (record.stop_chatting === "false")
            return <button onClick={handleClick}>Stop</button>;
        else 
            return <button onClick={handleClick}>Start</button>;
    };

    const handleStopClose = () => {
        setStopOpen(false);
        setStopCustomer(null);
    };

    const handleStopConfirm = async () => {
        if (stopCustomer) {
            await customer_stop(stopCustomer);
            handleStopClose();
        }
    };

    const customer_stop = async (stopCustomer) => {
        try {
            const stop_url = `${apiUrl}/stop_chatting/${stopCustomer._id}`;
            await httpClient(stop_url);
            // notify(`Calling ${phone_number}`, 'info');
            notify(`Stop Customer ${stopCustomer.name}`, {type: 'info'});
            refresh();
        } catch (e) {
            console.error(e);
            notify(`Error stop ${stopCustomer.name}`, {type: 'warning'});
        }
    };

    return (
        <>       
            <List component="div" title='Customers' {...props} pagination={<Pagination />} >
                <ListToolbar />
                <Datagrid rowClick="edit">
                    {/* <TextField source='_id' /> */}
                    <TextField source='bot_type' />
                    <TextField source='name' />
                    <TextField source='real_name' />
                    <TextField source='email' />
                    <TextField source='real_email' />
                    <TextField source='phone' />
                    <TextField source='country' />
                    <TextField source='business' />
                    <TextField source='company' />
                    <TextField source='spam_message' />
                    <TextField source='is_blocked' />
                    <TextField source='block_time' />
                    <StopButton />
                    <DeleteButton />
                </Datagrid>
            </List>
      
            {/* Confirm delete customer */}
            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this customer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm delete customer */}
            <Dialog open={stopOpen} onClose={handleStopClose}>
                <DialogTitle>Confirm Stop</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to stop this customer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleStopClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleStopConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
    
};

export default CustomerList;
